// 테마 강조 색상
$primary-accent-color: var(--primary-accent-color);
$secondary-accent-color: var(--secondary-accent-color);

// 기본 색상
$base-text-color: var(--base-text-color);
$base-icon-color: var(--base-icon-color);
$base-bck-color: var(--base-bck-color);
$base-border-color: var(--base-border-color);

// 모드 색상
$primary-depth-color: var(--primary-depth-color);
$secondary-depth-color: var(--secondary-depth-color);
$tertiary-depth-color: var(--tertiary-depth-color);
$mode-accent-color: var(--mode-accent-color);

// 폰트 사이즈
$x-large-font: 24px;
$large-font: 18px;
$medium-font: 14px;
$small-font: 11px;
$x-small-font: 10px;

// 경고 단계 색상
$warning-level-0: #8b8b8b;  // Gray (경고 확인 불가)
$warning-level-1: #7eda94;  // Green (낮은 단계 경고)
$warning-level-2: #ffc107;  // Yellow (중간 단계 경고)
$warning-level-3: #fd7e14;  // Orange (높은 단계 경고)
$warning-level-4: #f16270;  // Red (심각한 경고)

$base-border-radius: 4px;