@import "./focuswin.variables.base.scss";
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
};

* {
    box-sizing: border-box;
};

body {
    font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
};

.app {
    background-color: $base-bck-color;
    display: flex;
    height: 100%;
    width: 100%;

    #fw-app {
        display: flex;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .container {
        height: 100%;
        flex-direction: column;
        display: flex;
        background-color: $base-bck-color;
    }
};

.fw-flex-box {
    display: flex;

    &.row { flex-direction: row }
    &.col { flex-direction: column }
    &.j-center { justify-content: center }
    &.a-center { align-items: center }
};

.content {
    background-color: $base-bck-color;
    width: 100%;
};

.fw-grid-page {
    width: 100%;
    height: 100%;
    position: relative;
    .fw-grid-content, .fw-grid { width: 100%; }
};

// 상태값
.status {
    display: inline-flex;
    align-items: center;
    &.unde { --color: #575757; }
    &.good { --color: #2cb52c; }
    &.warn { --color: #fad24d; }
    &.erro { --color: #ed4832; }
};

.status::before {
    --diameter: 10px;
    content: " ";
    background-color: var(--color);
    border-radius: calc(var(--diameter) / 2);
    display: inline-block;
    height: var(--diameter);
    margin-right: calc(var(--diameter) / 2);
    width: var(--diameter);
    content: " ";
};

// notify
.dx-overlay-content.dx-toast-content {
    // warning
    &.dx-toast-warning {
        background-color: #ff7707;
    }
    // info
    &.dx-toast-info {
        background-color: $primary-accent-color;
    }
};

.dx-context-menu .dx-submenu {
    background-color: $primary-depth-color;
};

// rollers order 
.dx-dateview-rollers {
    display: flex;
};

.dx-dateviewroller-day {
    order: 3;
};

.dx-dateviewroller-month {
    order: 2;
};

.dx-dateviewroller-year {
    order: 1;
};

// 페이지 컨텐츠 높이
.fw-page-content {
    position: relative;
    min-height: calc(100vh - 48px);
    background-color: $base-bck-color;
};

@media (max-height: 901px) {
    .fw-page-content {
        min-height: calc(900px);

        .content-footer {
            bottom: 48px;
        }
    }
};

.dx-toolbar-menu-section {
    .dark-mode & {
        border-bottom: 1px solid rgb(99, 99, 99);
    }
}

.dx-calendar {
    background-color: var(--primary-depth-color);
    .dx-calendar-body thead th, .dx-calendar-cell {
        color: var(--base-text-color);
    }
    .dx-calendar-cell.dx-calendar-other-view.dx-calendar-cell-in-range span, .dx-calendar-cell.dx-calendar-other-view.dx-calendar-cell-range-hover span, .dx-calendar-cell.dx-calendar-other-view.dx-calendar-range-end-date span, .dx-calendar-cell.dx-calendar-other-view.dx-calendar-range-start-date span {
        color: var(--base-text-color);
        opacity: .8;
    }
}

.dx-button-mode-text, .dx-context-menu .dx-menu-item, .dx-popup-title, .dx-overlay-wrapper, .dx-toolbar, .dx-texteditor-input, .dx-widget, .dx-button-mode-text .dx-icon, .dx-dropdowneditor-icon, .dx-list-item .dx-icon, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
    color: #000;
}

.dark-mode {
    .dx-button-mode-text, .dx-context-menu .dx-menu-item, .dx-popup-title, .dx-overlay-wrapper, .dx-toolbar, .dx-texteditor-input, .dx-widget, .dx-button-mode-text .dx-icon, .dx-dropdowneditor-icon, .dx-list-item .dx-icon, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content, .dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button-normal, .dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button-normal .dx-icon, .dx-icon-to:before, .dx-fileuploader-input-label, .dx-button .dx-icon, .dx-treelist-headers, .dx-treelist-container {
        color: #fff;
    }
    .dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
        color: rgb(255 255 255 / 54%);
    }
    .dx-checkbox-icon {
        border: 2px solid rgba(255, 255, 255, .34);
    }
}

.dark-mode {
    .dx-searchbox .dx-icon-search::before, .dx-placeholder {
        color: rgba(255, 255, 255, .7);
    }
    .dx-tabpanel-tabs-position-left .dx-tabs {
        border-right: 1px solid #616161;
    }
    .dx-treelist .dx-row-lines>td {
        border-bottom: 1px solid #818181;
    }
    .dx-treelist-headers .dx-treelist-table .dx-row>td {
        border-bottom: 1px solid #818181;
    }
    .dx-treelist-borders>.dx-treelist-filter-panel, .dx-treelist-borders>.dx-treelist-headers {
        border-top: 1px solid #696969;
    }
    .dx-treelist-borders>.dx-treelist-rowsview, .dx-treelist-borders>.dx-treelist-total-footer {
        border-bottom: 1px solid #777777;
    }
    .dx-treelist-borders>.dx-treelist-headers, .dx-treelist-borders>.dx-treelist-rowsview, .dx-treelist-borders>.dx-treelist-total-footer {
        border-left: 1px solid #474747;
        border-right: 1px solid #474747;
    }
    .dx-tab {
        color: rgba(255, 255, 255, .87);
        .dx-icon {
            color: rgba(255, 255, 255, .87);
        }
        &.dx-tab-selected {
            color: var(--primary-accent-color);
            .dx-icon {
                color: var(--primary-accent-color);
            }
        }
    }
}

.dx-tabs-styling-mode-primary.dx-tab-indicator-position-right .dx-tab-selected::after {
    background-color: var(--secondary-accent-color);
}

.dx-tab.dx-tab-selected {
    color: var(--primary-accent-color);
    .dx-icon {
        color: var(--primary-accent-color);
    }
}

.dx-selectbox-popup-wrapper .dx-list {
    background-color: var(--primary-depth-color);
}

.dx-list .dx-empty-message, .dx-list-item {
    color: var(--base-text-color) !important;
    background-color: transparent !important;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.dx-state-hover {
        background-color: rgba(125, 125, 125, .2) !important;
    }
    &.dx-state-active {
        background-color: rgba(125, 125, 125, .3) !important;    
    }
}

.dx-loadpanel-content {
    background-color: $secondary-depth-color;
    border: 1px solid $primary-depth-color;
}

.dark-mode {
    .dx-list-select-all::after {
        background-color: #6f6f6f;
    }
    .dx-header-filter-menu .dx-popup-content {
        border-bottom: 1px solid #6f6f6f;
    }
}

.dx-button.dx-button-default.dx-button-mode-contained {
    background-color: var(--primary-accent-color);
    &.dx-state-hover {
        background-color: var(--primary-accent-color);
        filter: brightness(.9);
    }
}

.dx-button-mode-outlined.dx-button-default {
    border: 1px solid var(--primary-accent-color);
    color: var(--primary-accent-color);
    &.dx-state-hover {
        background: rgba(150, 150, 150, .1);
    }
}

.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
    background-color: var(--primary-accent-color);
}

.dx-checkbox-checked .dx-checkbox-icon {
    background-color: var(--primary-accent-color);
}