@import "../../../style/focuswin.variables.base.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-icon-color;
  }
  .header-toolbar.dx-toolbar {
    padding: 5px 5px 5px 0;
    background-color: $secondary-depth-color;
  }

}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: 60px;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
  .dx-button {
    height: auto;
    transition: all .4s;

    &:hover {
      background: none;
      opacity: .6;
    }

    &:active {
      background: none;
    }
    .dx-button-content {
      padding: 0;
      font-size: 16px;
    }
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.dx-toolbar-after {
  .dx-toolbar-item-content {
    .dx-button-mode-contained {
      margin-right: 5px;
      transition: all 300ms;
      &.active {
        background-color: $primary-accent-color;        
      }
    }
  }
}