@import "../../../style/focuswin.variables.base.scss";

.fw-content-header {
    height: 53px;
    padding: 10px 15px 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.fw-unde-cons-container {
    margin: 0 10px;
    background-color: $secondary-depth-color;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 40px 40px;
    height: 650px;
    pointer-events: none;
    .fw-unde-cons-header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding-top: 10px;
        img {
            width: 20px;
            height: 20px;
        }
        .fw-unde-header-title {
            margin-left: 5px;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            color: $base-text-color;
        }
    }
    .fw-unde-cons-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        .fw-unde-detail-wrapper {
            padding-left: 20px;
            .fw-unde-detail-title {
                font-size: 36px;
                font-weight: 800;
                color: $base-text-color;
                margin-bottom: 20px;
            }
            .fw-unde-detail-detail {
                font-size: 14px;
                color: $base-text-color;
            }
        }
        .fw-unde-img-wrapper {
            img {
                width: 700px;   
                filter: grayscale(.6);
            }
        }
    }
}

.screen-x-small, .screen-small, .screen-medium {
    .fw-unde-cons-container {
        padding: 20px 20px 40px 20px;
        height: 400px;
        .fw-unde-cons-body {
            .fw-unde-detail-wrapper {
                padding: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
    .fw-unde-img-wrapper {
        display: none;
    }
}