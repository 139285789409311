@import '../../../../style/focuswin.variables.base.scss';

.equi {
    .main {
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }
        &::-webkit-scrollbar-thumb { 
            background-color: rgb(199, 199, 199);
        }
        &::-webkit-scrollbar-button {
            display: none;
        }    
    
        .header {
            padding-bottom: 10px;
        }
        .card-contents {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 1300px;
            .dx-scrollable.dx-scrollview {
                width: calc(40%);
                margin-right: 20px;
            }
            .fw-chart-content {
                width: calc(60% - 20px);
            }
        }
    }
    
    .dash-card {
        .header {
            padding-bottom: 10px;
        }
        &:first-child {
            height: calc(250px + 65px);
        }
        &:last-child {
            height: calc(100% - 315px - 50px);
        }
    }
}