@import '../../../../style/focuswin.variables.base.scss';

.fw-grid-page-detail-wrapper {
    flex-wrap: wrap;
    & > div {
        width: 100%;
        &.fw-grid-detail-chart {
            .card {
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 0;
            }            
        }
    }
}

.fw-grid-page.fw-flex-box col {
    .dx-scrollview {
        width: 100%;
    }
}

.dx-datagrid-borders>.dx-datagrid-pager {
    position: absolute;
    top: 100%;
};

.dx-datagrid-borders>.dx-datagrid-rowsview.dx-last-row-border tbody:last-child>.dx-data-row:nth-last-child(2)>td {
    border-bottom-width: thin;
}

// grid-bck
.dx-datagrid>.dx-datagrid-headers, .dx-datagrid>.dx-datagrid-rowsview, .dx-datagrid>.dx-datagrid-total-footer {
    background-color: $secondary-depth-color;
}

// title
.fw-grid-title {
    padding: 15px 0 2px 15px;
    font-size: 18px;
}

// check column
.fw-check-column {
    color: $primary-accent-color !important;
}

// fake pager
.fw-fake-pager {
    width: 100%;
    height: 56px;
    opacity: 0;
    pointer-events: none;
}

// header-row
.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row>td {
    vertical-align: middle;
}

// alter td
.dx-datagrid .dx-row-alt>td {
    background-color: transparent;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $primary-accent-color;
        opacity: .1;
    }
}

// hover td
.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)>td:not(.dx-focused) {
    background-color: transparent;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $primary-accent-color;
        opacity: .2;
    }
}

// active td
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td {
    background-color: transparent;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $primary-accent-color;
        opacity: .3;
    }
}

// table border color
.dx-datagrid .dx-column-lines>td {
    border-left: 1px solid rgba(150, 150, 150, .2);
    border-right: 1px solid rgba(150, 150, 150, .2);
}

.card .dx-datagrid .dx-column-lines>td {
    border-left: 1px solid rgba(150, 150, 150, .35);
    border-right: 1px solid rgba(150, 150, 150, .35);
}

// checkbox color
.dx-checkbox.dx-checkbox-checked.dx-state-disabled .dx-checkbox-icon, .dx-checkbox.dx-checkbox-checked.dx-state-readonly .dx-checkbox-icon, .dx-checkbox.dx-checkbox-indeterminate.dx-state-disabled .dx-checkbox-icon, .dx-checkbox.dx-checkbox-indeterminate.dx-state-readonly .dx-checkbox-icon {
    background-color: $primary-accent-color;
}

// summary 
.dx-datagrid-total-footer {
    tbody .dx-footer-row {
        td {
            padding: 10px 0;
            display: inline-flex;
            &.dx-command-select {
                display: none;
            }
            .dx-datagrid-summary-item {
                width: auto;
                overflow: visible;
                padding: 0 10px;
            }
        }
    }
}

// 그리드 보더 색상
.dx-datagrid-borders>.dx-datagrid-filter-panel, .dx-datagrid-borders>.dx-datagrid-headers, .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    .dark-mode & {
        border-top: 1px solid #4f4f4f;
    }
}

.dx-datagrid-headers, .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    .dark-mode & {
        border-bottom: 1px solid #4f4f4f;
    }
}

.dx-datagrid-borders>.dx-datagrid-headers, .dx-datagrid-borders>.dx-datagrid-rowsview, .dx-datagrid-borders>.dx-datagrid-total-footer, .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row>td {
    .dark-mode & {
        border-left: 1px solid #4f4f4f;
        border-right: 1px solid #4f4f4f;
        border-bottom: 1px solid #4f4f4f;
    }
}

// 그리드 텍스트 색상
.dx-datagrid {
    td { color: #000; }

    .dark-mode & {
        td { color: #fff; }
    }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td {
    .dark-mode & {
        color: #fff;
    }
}

.dark-mode .dx-datagrid-nodata { color: rgba(255, 255, 255, .7) }

.dark-mode .dx-datagrid .dx-header-filter-empty { color: #fff };

// 그리드 보더 색상
.dark-mode .dx-datagrid .dx-row-lines>td {
    border-bottom: 1px solid #4f4f4f;
}

.dark-mode {
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines>td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines>td {
        border-color: #4f4f4f;
    }
}

// 그리드 스크롤
.dx-datagrid {
    .dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
        background-color: rgb(193 193 193);
    }
}

// 페이저
.dx-datagrid-pager.dx-pager {
    display: flex;
    flex-direction: row;
    justify-content: center;    
    align-items: center;
    .dx-pages {
        font-weight: bold;
    }
}

// 열 일괄 체크
.fw-all-checker {
    width: 100%;
    align-items: center;
    .fw-all-checker-checkbox-wrapper {
        .fw-all-checker-checkbox {
            margin: 5px 4px 0;
        }
    }
}

// 가로 나열 그리드 페이지
.fw-horizon-grid-wrapper {
    .fw-horizeon-grid {
        padding: 0 5px;
        width: 100%;
    }
    &.col {
        max-height: 950px;
    }
}

// 요약 그룹 행
.fw-summary-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .fw-group-title {
        padding: 5px 10px;
        color: #fff;
        background-color: $secondary-accent-color;
        border-radius: 8px;
    }
    .fw-group-detail {
        margin-left: 10px;
        margin-right: 10px;
        b {
            font-size: 14px;
        }
    }
}

// 그리드 조회 셀
.fw-grid-btns-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .fw-grid-value {
        width: calc(100% - 20px);
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
    i {
        color: $secondary-accent-color;
        transition: all .4s;
        &:hover {
            transform: scale(1.2);
            cursor: pointer;
        }
    }
}

.dx-datagrid .dx-link {
    text-decoration: none;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused):not(.dx-cell-modified):not(.dx-datagrid-invalid) {
    position: relative;
    background-color: transparent;
    color: #000;
    .dark-mode & {
        color: #fff;
    }
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $primary-accent-color;
        opacity: .3;
    }
}

.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
    background-color: var(--primary-depth-color);
}
.dx-datagrid-group-opened, .dx-datagrid-group-closed {
    .dark-mode & {
        color: #dfdfdf;
    }
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
    .dark-mode & {
        border-bottom-color: #333333;
    }
}

.dark-mode {
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        &:hover {
            background-color: rgb(70, 70, 70) !important;
        }
        .dx-datagrid-text-content {
            color: #fff !important;
        }
    }
}

// 변경 전-후 열
.fw-before-column  {
    color: #427bff !important;
}

.fw-after-column  {
    color: #ff5d5d !important;
}