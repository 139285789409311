@import '../../../style/focuswin.variables.base.scss';

.fw-detail-panel {
  --detail-panel-width: 350px;
}

.screen-x-small .fw-detail-panel {
  --detail-panel-width: 100vw;
}

.fw-grid-page .dx-scrollview {
  flex-grow: 1;
};

.fw-detail-panel {
  width: var(--detail-panel-width);
  height: calc(100vh - 48px);
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(var(--detail-panel-width) * -1);
  background: $primary-depth-color;
  transition: right 400ms;
  z-index: 1;

  .embedded.dx-viewport & {
    top: 0;
  }

  &.open {
    right: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  }

  &.pin {
    position: absolute;
    transition: none;
    box-shadow: none;
    border-left: 1px solid var(--base-border-color);

    &.open {
      top: 0;
      position: relative;
    }
  }  

  .dx-layout-manager.dx-layout-manager-one-col .dx-item.dx-box-item {
    .dx-single-column-item-content .contact-fields-group {
      padding-top: 20px;
    }
  }

  .fw-image-row {
    padding-bottom: 20px !important;
    border-bottom: 1px solid rgba(150, 150, 150, .15);
  }

  .contact-fields-group {
    padding-top: 20px !important;
  }

  .fw-panel-wrapper {
    padding-bottom: 16px;
    height: calc(100% - 48px);
    width: var(--detail-panel-width);

    .fw-detail-pandel-toolbar {
      padding: 6px 6px 6px 16px;
    }

    .fw-detail-panel-data-part {
      padding: 8px 16px;
      scroll-behavior: smooth;
      &.border {
        border-bottom: 1px solid $base-border-color;
      }

      &-toolbar {
        padding: 10px 16px;
        margin-bottom: 24px;

        .dx-toolbar-after .dx-button {
          min-width: 90px;
        }
      }
      
      .dx-toolbar {
        background-color: $primary-depth-color;
      }
    }

    .fw-detail-panel-scroll {
      height: calc(100% - 20px);
    }

    .fw-detail-panel-title {
      font-weight: bold;
      margin-right: 8px;
    }

    .dx-texteditor.dx-editor-filled {
      background-color: rgb(150, 150, 150, .05);
    }

    .opportunities {
      padding: 0 0 10px 0;
      background-color: transparent;
    }

    .dx-accordion {
      .dx-accordion-item-title {
        vertical-align: middle;
        padding-right: 10px;
        display: flex;
        align-items: center;
      }

      .by-span {
        display: none;
      }
    }
  }
};



