@import '../../../../style/focuswin.variables.base.scss';

.fw-page-tab-header {
    width: 100%;
    padding: 10px 15px 5px;
    .fw-page-tab-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;        
        width: 100%;
        height: 38px;
        .fw-page-tab-title {
            text-align: center;
            font-size: 16px;
        };

        .fw-page-tab-item-wrapper {
            .dx-widget.dx-button {
                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        };

        .dx-button-mode-contained {
            background-color: $secondary-depth-color;
            transition: all .4s;
            &.dx-state-hover {
                filter: brightness(85%);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.fw-page-tab-panel {
    .dx-tabs {
        background-color: $primary-depth-color;
        .dx-tab {
            position: relative;
            background-color: $primary-depth-color;
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                background-color: $primary-accent-color;
                opacity: 0;
                transition: all .4s;
            }
            &.dx-state-hover::before {
                opacity: .2;
            }
        }
    }
}

.fw-page-tab-body {
    background-color: $secondary-depth-color;
    height: calc(100vh - 48px - 53px - 70px - 20px);

    @media (max-height: 901px) {
        height: calc(900px - 48px - 53px - 70px - 20px);
    }
}