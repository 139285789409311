@import "./focuswin.variables.base.scss";

.dx-icon-fw-faxs {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGWSURBVHgB5VeBUcMwDPxyDBAmwBsUJiBMQJmAjsAGhQkoE6RMABuUDdoNGiZINwApti+qiY1jkuY4/u4vdmwrb0mREyAOM2JF/OxIXlMQFX6JTcLDJXc+ERPEwRp4Jr5FrsmIr6JfEq/NtTNYAO9k3mGNQoQnTnBcKOIa2juDC9jDL2KOFgHKYR8CHolb6LiXaETlcB7MbgnFKyUH2rAydtb2BnvgyVUkhBUYGCxgFhjPMQyOkoQhXEB7HqfohjviFdIh194TP7jxUxll7CLmJZXosUJgocYW0DkHLPhQ2po254WCLjoMzvAF8UXMuUHgbUvJgXOxfg5RWMT8XPRzn/0/G4JLNN8SU2i3KzHO/RzN2T8NGRvzNUwOgfUAk5PvXfQnaL5+bP/WZyhVQCXaZcT8fd8CekNqEi7R7IoPFoXDo5v7C+gaYee0og8PZDh0ceZcgUAIGP+7EKUKOBNt9kbmjGct97wYsxDV3wPBBBkYfGLW/28+hYWZ+ID+d7+xKjhWK2ewMsJkHJdI+0X/5nazodr2FwiiEciqN1tqAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-phone {
    background-image: '';
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-department {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFqSURBVHgBxZYNccMwDIXfRmAZgnkMCiEMNgiGUAbNEKQMMghjEAgdg5TBMgSb1cZX161tyY3T707XnyjSO1uWBeShjfXG/ibrp/8WoXMS+9ahMJtIcmsNClExkpP9TL4sHsFnxfSrBL4iAUrgW2QFvgW+IwoxIF0DAwpSMwS8ozBrHCv9WvVrLIQytnOS03d24blIitBlj/NCG5FZeLkCZuPuAh4iz2pjbwh3Nfrf7jstf6hP7I19TJ9sOJeO1Db3TG5t7Sfzt0DhspPR0ua2Vv9iojiviMSjLjb33b71Ymr3oX8K/ILrMI8AlyomYAmiAl6838+4nVUixwGF8FXbIp8W4StbcZLfIqJFem44bEcH3hmuwUcxYzbkvGM6b/n5L45ecBWoCLkT7BP4cGcDRQK4Xe4X8zOSAO60K5mKvyR+tAXJvYKcgRFXWWedSK4gRyVE6GsvfOI08dLLDTKHTS+mPWkUu4dT+P/E0/Z9ep8RigAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-export-excel {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAlQTFRFAIQAWJ9Y////6Z5Z5AAAAEtJREFUeNpsj1sSADAEAyP3P3RHPEar/OjOomC4E7CVHCGDjvWIEpTILGWw2tNoUjOKeJGGocehG/FsEZlGkPlT0drS8blsXXsEGAB4dgDhOKwAmwAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-export-pdf {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7klEQVR4nGN4aOP4///kyf/JpRko0QyiGWAcUgGmC7CAx76B/zdHx2E3gZAL/r148f9pcur/087u///+/Em6C/4BNd3TM/q/2NyaPBeAwANrO5xeIOiCvx8//r+roPb//4RJ/3++e0+GC7bt/H/e2eP/++07/59asPD//+s3//+/AcTPnhN2wZ83b/+fNLH6fyMr5/+tufP+v25p+/+qrvH/5/0H/+8PDMbvgm/7DwGdrvr/kbs3iqGfL1/+/xxoIAjjdMH7xUv/v8gpAGr2/P//9DmsgYc3DECmg5z7/8o1rHGPDIhKiSS5YMByIwC9HkemAtev8QAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-insert-data {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAxQTFRFAW8bAvw9MzMz////e+dIAQAAAAR0Uk5T////AEAqqfQAAAA4SURBVHjaYmBGAwzMDMiACSTAyACXZUQXIKyCaAFGmKWMMAEEQFOBpgXDDJwCTCiAmQHd+wABBgDxCgG0lETJuQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-update-data {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACRQTFRFAAAA/84Ad6bC3HEl/952AFu+ss7dq0cTvZiPlmxi+fn5////JNiWgAAAAAx0Uk5T//////////////8AEt/OzgAAAFxJREFUeNpcz9EOgCAIQNEramb+//+GBhODwcMZY8D4BavBAch1EQCRIAyqglDYoALFZEElJZe5o6LgovWQ85ZvzEXvsU1TNG8Hk75hSY+g/7TWCDA/5ACLV4ABALhaBtmhfT4jAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};
.dx-icon-fw-delete-data {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9QTFRFc3V3NjY8REdHtbXG////WFLAwwAAAAV0Uk5T/////wD7tg5TAAAAW0lEQVR42lyPCQ4AIQgDe/D/N69W2ajERBmQUtQTGMc3sPQTB4CbWMiXAplLWDNmabwRukCaVt6gSNUJxCbYOaxjhlIFXS2bRdyybgHTy0tvGlezJlxenvgEGAC3GgM45K3ISAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 0px 0px;
};