@import '../../../../style/focuswin.variables.base.scss';

.ship {
    .fw-dash-body {
        display: flex;
        flex-direction: column;
    }
    .fw-dash-row-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }
        &::-webkit-scrollbar-thumb { 
            background-color: rgb(199, 199, 199);
        }
        &::-webkit-scrollbar-button {
            display: none;
        }            
    }

    .ship-char .card-contents .fw-chart-content {
        padding: 10px 0;
        &.comm {
            width: 100%;
        }
    }

    .card-contents {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 1100px;
        
        .fw-chart-content {
            padding: 10px 0;
            &.pies {
                width: calc(40%);
            }
            &.comm {
                width: calc(60%);
            }
        }
    }

    .dash-card {
        width: calc(50% - 30px);
        min-width: 600px;
        height: 315px;
    
        &.ship-char {
            width: calc(100% - 30px);
            height: calc(100% - 345px - 30px);
            min-width: auto;
        }
        .header {
            padding-bottom: 10px;
        }
        
        .card-contents {
            min-width: auto;
            position: relative;
            border-top: 1px solid $base-border-color;
            border-bottom: 1px solid $base-border-color;
        }
        .fw-scroll-grid {
            width: 100%;
        }
    }
}