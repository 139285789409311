@import "../../../style/focuswin.variables.base.scss";

.fw-account-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fw-account-wrapper {
  width: 70%;
  height: 65%;
  background-color: var(--secondary-depth-color);
  border-radius: 18px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .screen-small & {
    width: 95%;
    height: 95%;
  }

  .screen-x-small & {
    width: 100%;
    height: 100%;
  }
}

.fw-main-content {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 65%;
  color: #000;

  .dark-mode & { color: #fff };

  .screen-medium & {
    height: 50%;
    .main-img {
      width: 250px;
    }
  }

  .screen-x-small &, .screen-small & {
    display: none;
  }

  .main-img {
    width: 60%;
    margin: 50px 0;
  }

  .logo-content {
    .logo-img {
      width: 30px;
    }

    .logo-text {
      .logo-detail {
        font-size: 12px;
        letter-spacing: -1px;
      }

      .logo-title {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 2px;
        text-align: justify;
        color: #080059;
      }
    }
  }
}

.fw-content {
  position: relative;
  width: 25%;
  min-width: 350px;
  height: 80%;
  padding: 40px;
  margin-right: 5%;
  border-radius: 20px;
  background-color: var(--primary-accent-color);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .1);

  .screen-small & {
    width: 90%;
    height: 90%;
    margin: 0 5%;
  }

  .screen-x-small & {
    width: 100%;
    height: 100%;
    margin-right: 0;
    border-radius: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: linear-gradient(180deg, #fff0, #ffffff26);
  }

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .title {
      font-size: 26px;
      font-weight: 600;
      color: #fff;
    }
  }

  .dx-texteditor.dx-editor-underlined {
    background-color: rgba(255, 255, 255, .15);
    transition: all .4s;

    &.dx-state-hover {
      background-color: rgba(255, 255, 255, .2);

      &::after {
        border-bottom: 1px solid rgba(255, 255, 255, .62);
      }
    }

    &.dx-state-focused {
      background-color: rgba(255, 255, 255, .25);

      &::after {
        border-bottom: 1px solid rgba(255, 255, 255, .42);
      }
    }

    &::before {
      display: none;
    }

    &::after {
      border-bottom: 1px solid rgba(255, 255, 255, .12);
    }

    .dx-texteditor-input,
    .dx-placeholder {
      font-size: 13px;
      padding: 10px;
      color: #fff;

      &.dx-placeholder {
        padding: 0 10px;
      }
    }
  }

  .dx-checkbox {
    color: #fff;

    &.dx-checkbox-checked .dx-checkbox-icon {
      border: none;
      background-color: #fff;
    }

    .dx-checkbox-icon {
      border: 2px solid rgba(255, 255, 255, .54);
      color: var(--primary-accent-color);
    }

    .dx-checkbox-text {
      margin-left: 6px;
    }
  }

  .dx-button.dx-button-mode-contained.dx-button-default {
    background-color: var(--primary-accent-color);
    filter: brightness(.95);
    transition: all .4s;
    &.dx-state-hover {
      filter: brightness(.9);
    }
  }
}

.account-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, .57);
}