@import '../../../style/focuswin.variables.base.scss';

.dx-popup-content .dx-datagrid-header-panel {
  padding: 10px 0;
  .dx-item-content.dx-toolbar-item-content {
    padding: 5px;
  }
}

.dx-datagrid-header-panel {
  padding: 10px 15px 5px 15px;
  .fw-grid-header {
    font-size: 16px;
    padding-right: 5px;
  }
  .fw-header-date-search-panel {
    .dx-checkbox {
      padding: 0 5px 0 10px;
    }
  }

  .dx-item.dx-toolbar-item .dx-item-content.dx-toolbar-item-content {
    overflow: visible;
  }

  .dx-item-content.dx-toolbar-item-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    .fw-tooltip-button-wrapper, .dx-editor-outlined {
      &:not(:first-child) {
        margin-left: 5px;
      }

      &.dx-editor-outlined {
        background-color: $secondary-depth-color;
        transition: all .4s;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
        &.dx-state-hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, .48); 
        }
        &.dx-state-focused {
            box-shadow: 0 0 5px $secondary-accent-color; 
        }
        &::after, &::before {
            display: none;
        }        
        &.dx-end-datebox, &.dx-start-datebox {
          box-shadow: none !important;
        }
      }
    }
  }

  .dx-toolbar-items-container {
    .dx-state-disabled .dx-buttongroup.dx-buttongroup-mode-contained {
      background-color: $secondary-depth-color;
      color: $base-text-color;
      opacity: .6;
      box-shadow: none;
    }
    .dx-button.dx-button-mode-contained, .dx-buttongroup.dx-buttongroup-mode-contained {
      background-color: $secondary-depth-color;
      color: $base-text-color;
      margin-right: 0;

      .dx-icon {
        color: $base-text-color;
      }

      &:hover, &:active {
        filter: brightness(.9);
      }
      &.dx-state-disabled {
        background-color: $secondary-depth-color;
        color: $base-text-color;
        opacity: .8;
        .dx-icon {
          color: $base-text-color;
          opacity: .5;
        }
      }
    }
  }
}

.dx-datagrid-search-panel.dx-editor-filled.dx-editor-filled {
  background-color: $secondary-depth-color;
  transition: all .4s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
  &.dx-state-hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .48); 
  }
  &.dx-state-focused {
      box-shadow: 0 0 5px $secondary-accent-color; 
  }
  &::after, &::before {
      display: none;
  }
}

.dx-toolbar-menu-section {
  .dx-item.dx-list-item {
    min-width: 240px;
  }
  .fw-header-date-search-panel {
    .dx-end-datebox {
      margin-top: 0 !important;
    }
    .dx-checkbox {
      padding-left: 10px;
    }
  }
  .dx-item-content.dx-list-item-content {
    .dx-editor-outlined, .fw-tooltip-button-wrapper {
      width: 100% !important;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .fw-grid-before-box, .fw-dash-before-box {
    width: 100% !important;
  }
  .dx-datagrid-search-panel {
    width: 100% !important;
    min-width: 160px;
    margin: 0;
  }
  .dx-button.dx-button-mode-contained {
    width: 100%;
  }
}

.dx-datagrid-header-panel .dx-toolbar {
  padding: 0;
}

.dx-filemanager-toolbar .dx-toolbar {
  padding: 0;
}

.dx-toolbar {
  background: transparent;
  padding: 10px 15px 5px;

  .fw-header-date-search-panel {
    .dx-checkbox {
      padding: 0 5px 0 10px;
    }
  }

  .fw-dash-header {
    font-size: 16px;
    padding-right: 5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .dx-toolbar-before {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dx-toolbar-after {
    display: flex;
    align-items: center;
    .dx-toolbar-menu-container {
      display: flex;
      align-items: center;
    }
  }

  .dx-item.dx-toolbar-item {
    .dx-toolbar-item-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .dx-button-mode-contained.dx-button-normal {
        background-color: $secondary-depth-color;
        color: $base-text-color;
        margin-right: 0;
        transition: all .4s;
  
        .dx-icon {
          color: $base-text-color;
        }
  
        &:hover, &:active {
          filter: brightness(.9);
        }
        &.dx-state-disabled {
          background-color: $secondary-depth-color;
          color: $base-text-color;
          opacity: .8;
          .dx-icon {
            color: $base-text-color;
            opacity: .5;
          }
        }
      }
      .fw-tooltip-button-wrapper, .dx-editor-outlined {
        &:not(:first-child) {
          margin-left: 5px;
        }
  
        &.dx-editor-outlined {
          background-color: $secondary-depth-color;
          transition: all .4s;
          box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
          &.dx-state-hover {
              box-shadow: 0 1px 3px rgba(0, 0, 0, .48); 
          }
          &.dx-state-focused {
              box-shadow: 0 1px 3px $secondary-accent-color; 
          }
          &::after, &::before {
              display: none;
          }        
          &.dx-end-datebox, &.dx-start-datebox {
            box-shadow: none !important;
          }
        }
      }      
    }
  }
}

.dx-tooltip-wrapper .dx-overlay-content {
  color: $base-text-color;
}