@import "../../../style/focuswin.variables.base.scss";

// 프로필
.fw-profile-body {
  height: calc(100vh - 48px - 70px - 20px - 53px);
};

@media (max-height: 901px) {
  .fw-profile-body {
    height: calc(900px - 48px - 70px - 20px - 53px);
  }
};

#fw-profile-container {
  .fw-profile-header {
    padding: 10px 15px 5px;

    span {
      height: 38px;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }

  .fw-profile-body {
    background-color: $secondary-depth-color;
    padding: 20px 0 0;

    .fw-user-row {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(150, 150, 150, .3);

      .fw-user-row-detail {
        margin: 0 30px;
        padding: 10px 0;

        .fw-user-detail-item {
          padding: 5px 0;

          .fw-user-name {
            font-size: 16px;
            font-weight: bold;
          }

          .fw-user-id {
            font-size: 13px;
          }
        }
      }
    }

    .fw-dropzone {
      .fw-default-img-wrapper {
        position: absolute;
        top: 0;
        left: -10%;
        transform: translateY(-50%);
        border-radius: 50%;
        margin: 0;
        background-color: $secondary-accent-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .fw-profile-form {
      max-width: 1600px;
      padding: 0 20px;
      .form-image-view.fw-flex-box col {
        align-items: start;
        padding-top: 10px;

        .fw-label {
          width: 180px;
          margin-left: 12px;
          text-align: left;
        }
      }

      .dx-widget input {
        font-size: 12px;
      }

      .form-image-view {
        padding-top: 10px;
        align-items: start;
      }
    }

    .dx-toolbar {
      background-color: $secondary-depth-color;
    }
  }
}