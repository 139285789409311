@import "../../../../focuswin/style/focuswin.variables.base.scss";

.fw-cell-button {
    color: $base-icon-color;
    box-shadow: 0 1px 2px rgba(120, 120, 120, .5);
    background-color: $secondary-depth-color;
    width: 30px !important;
    transition: all 300ms;
    position: relative;

    &:hover {
        background-color: $tertiary-depth-color;
    }

    &::before {
        inset: 0;
        text-align: center;
        font-size: 24px;
        color: $base-icon-color;
    }
}

.dx-row.dx-data-row.dx-row-lines {
    background-color: $secondary-depth-color;
    &[aria-level='1'] {
        font-weight: bold;
    }

    &[aria-level='2'] {
        filter: brightness(.99);
    }

    &[aria-level='3'] {
        filter: brightness(.95);
    }

    &[aria-level='4'] {
        filter: brightness(.9);
    }

    .dark-mode & {
        &[aria-level='1'] {
            font-weight: bold;
        }
    
        &[aria-level='2'] {
            filter: contrast(1.1);
        }
    
        &[aria-level='3'] {
            filter: contrast(1.2);
        }
    
        &[aria-level='4'] {
            filter: contrast(1.3);
        }
    }
}

.dx-treelist-container>.dx-treelist-headers, .dx-treelist-container>.dx-treelist-rowsview {
    background-color: $primary-depth-color;
}