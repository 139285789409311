.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.side-navigation-menu .dx-scrollable-content > .dx-treeview-node-container {
  padding-bottom: 48px;
}