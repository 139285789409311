@import '../../../../style/focuswin.variables.base.scss';

.sale-purs {
    .clie {
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(199, 199, 199);
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        .card-contents {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 1100px;

            .fw-chart-content {
                padding: 10px 0;

                &.pies {
                    width: calc(40%);
                }

                &.comm {
                    width: calc(60%);
                }
            }
        }
    }

    .dash-card {
        height: calc(50% - 30px);
    }
}