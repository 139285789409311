@import '../../../../style/focuswin.variables.base.scss';

.kpis {
    .main {
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(199, 199, 199);
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        .card-contents {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 1100px;

            .fw-chart-content {
                padding: 0 10px;

                &.comm {
                    width: calc(50%);
                }
            }

            .fw-scroll-grid {
                width: calc(50%);
            }
        }
    }

    .subs {
        .card-contents {
            padding: 10px 0 0;
        }
    }

    .dash-card {
        height: calc(50% - 30px);
    }
}