@import "../../../style/focuswin.variables.base.scss";

.user-info {
  display: flex;
  align-items: center;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .image-container {
    background-color: $secondary-accent-color;
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);
    display: flex;
    justify-content: center;
    align-items: center;

    .user-image {
      height: 20px;
    }
  }

  .user-name {
    font-size: 14px;
    color: $base-text-color;
    margin: 0 9px;
  }
}

.user-panel {
  padding-right: 20px;
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }
  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }
  .dx-menu-item .dx-menu-item-content {
      padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}

.popup-content {
  .preference-row {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;
    &:first-child {
      padding-top: 16px;
    }
    .row-title {
      width: 140px;
    }
    .row-content {
      &.switch-content {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          background-color: $mode-accent-color;
          opacity: 0.5;
        }
      }
      .color-palette {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin: 0 4px;
        border-radius: 50%;
        transition: all 300ms;
        &.active {
          transform: scale(1.3);
        }
      }

      .dx-button {
        transition: all 300ms;
        margin-top: 0;
        opacity: 0.5;

        &:first-child {
          margin-right: 5px;
        }
        &.active {
          background-color: $mode-accent-color;
          opacity: 1;
        }
      }
    }
  }
}

// 프로필
.dx-popup-content {
  .fw-profile-form {
    .fw-profile-form-header {
      padding-bottom: 20px !important;
      border-bottom: 1px solid rgb(150, 150, 150, .25);
      .fw-profile-form-header-detail {
        .dx-box-flex.dx-box.dx-widget.dx-collection {
          .dx-item.dx-box-item {
            &:first-child .form-editor-input {
              padding: 20px 0 0;
              font-size: 14px;
              font-weight: bold;
            }        
          }
        }
        .dx-field-item {
          padding: 5px 0 !important;
          .dx-texteditor {
            background-color: transparent;
            &::after, &::before {
              display: none;
            }
            .dx-texteditor-container {
              .form-editor-input {
                padding: 0;
                font-size: 13px;
                color: $base-text-color;
              }
            }
            .dx-texteditor-label {
              display: none;
            }
          }

          .fw-btns-wrapper {
            padding: 10px 0;
            .dx-button {
              width: 140px;
            }
          }
        }
      }
    }

    .fw-profile-form-body {
      padding: 10px 0 !important;
    }

    .fw-profile-form-footer {
      .form-image {
        width: 100% !important;
      }
      .fw-drawing-button-container {
        width: 100% !important;
        .dx-button {
          width: 100% !important;
        }
      }
    }
  }
}

// 비밀번호 변경
.fw-pass-popup {
  .dx-popup-content {
    padding: 5px;

    .fw-current-password-wrapper {
      .dx-field-item-content {
        .dx-field-item-content {
          padding-bottom: 20px;
          border-bottom: 1px solid rgb(150, 150, 150, .25);
        }
      }
      
    }

    .fw-popup-toolbar {
      padding: 20px 15px  10px !important;
      .dx-toolbar-after {
        width: 100%;
        padding: 0;
        .dx-toolbar-item-content {
          width: 100%;
          .dx-button {
            width: 100%;
          }
        }

      }
    }
  }
}
