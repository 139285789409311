@import '../../../../style/focuswin.variables.base.scss';

.fw-dash-body {
    background-color: $secondary-depth-color;
    height: calc(100vh - 48px - 53px - 70px - 20px);

    @media (max-height: 901px) {
        height: calc(900px - 48px - 53px - 70px - 20px);
    }
}

.dash-card {
    margin: 10px 15px 20px;
    box-shadow: 0 2px 4px rgba(150, 150, 150, .5);
    padding: 15px;
    .dark-mode & {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    }
}