@import '../../../../style/focuswin.variables.base.scss';

.dash-card {
    position: relative;
    .header {
        justify-content: space-between;
        .title {
            font-size: 14px;
        }
    }
    .card-contents {
        height: calc(100% - 28px);
    }
}

.fw-card-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    .dx-loadindicator-segment-inner {
        border-color: $primary-accent-color $primary-accent-color transparent;
    }
    .dx-loadindicator-segment2 .dx-loadindicator-segment-inner {
        border-right-color: transparent;
    }
    .dx-loadindicator-segment0 .dx-loadindicator-segment-inner {
        border-left-color: transparent;
    }
}