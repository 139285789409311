@import "../../../style/focuswin.variables.base.scss";

.content-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: $secondary-depth-color;
    color: $base-text-color;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    bottom: 0;
    z-index: 10;
    i {
        margin-right: 5px;
    }
    .footer-logo {
        .logo-img {
            width: 20px;
        }
    }

    .info {
        opacity: .5;
    }
}