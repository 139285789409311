:root {
    --mode-accent-color: #F3BF3E;
    --primary-accent-color: rgb(97, 146, 255);
    --secondary-accent-color: rgb(137 163 221);
    --base-bck-color: #e9e9e9de;
    --base-text-color: rgba(0,0,0, .87);
    --base-icon-color: rgba(0,0,0, .87);
    --base-border-color: #fff;
    --primary-depth-color: #fff;
    --secondary-depth-color: #fff;
    --tertiary-depth-color: #fff;
}

@font-face {
    font-family: DXIcons;
    src: local("DevExtreme Material Icons"),local("devextreme_material_icons"),url("../../../node_modules/devextreme/dist/css/icons/dxiconsmaterial.woff2") format("woff2"),url("../../../node_modules/devextreme/dist/css/icons/dxiconsmaterial.woff") format("woff"),url("../../../node_modules/devextreme/dist/css/icons/dxiconsmaterial.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}